import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';

import { IAppDealType } from '../../../types/AppDealTypes';
import { useStores } from '../../../hooks/useStores';

import StyledTabs from '../../UI/StyledTabs';
import StyledTab from '../../UI/StyledTab';
import { a11yProps } from '../../UI/TabPanel';

const AppsDealsTabs = ({ activeTab }: { activeTab: IAppDealType }) => {
    const { AppsDealsStore: store } = useStores();
    const history = useHistory();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const handleChange = (event: React.ChangeEvent<{}>, id: string) => {
        history.push({ hash: `#${id}` });
        store.resetFilters();
    };

    return (
        <StyledTabs centered scrollButtons="off" variant={isMD ? 'fullWidth' : 'standard'} value={activeTab} onChange={handleChange}>
            <StyledTab label="Заявки" {...a11yProps('applications')} />
            <StyledTab label="Сделки" {...a11yProps('deals')} />
            <StyledTab label="История" {...a11yProps('history')} />
        </StyledTabs>
    );
};

export default AppsDealsTabs;
