export const getActiveTab = (hash: string) => {
    if (hash.includes('applications')) {
        return 'applications';
    }

    if (hash.includes('deals')) {
        return 'deals';
    }

    if (hash.includes('history')) {
        return 'history';
    }

    return 'applications';
};
