import React from 'react';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStores } from '../../../hooks/useStores';
import { useStyles } from '../style';
import { IFilterTypes } from '../../../types/CommonTypes';

const ApartmentsFilterObjectType = observer(({ onClick }: { onClick: any }) => {
    const { ApartmentStore: store } = useStores();
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 2, sm: 3, md: 4 }}>
            <Typography variant="subtitle2">Объекты</Typography>

            <Box display="flex" flexWrap="wrap">
                {store.types.map(({ type_id, type_name }: IFilterTypes) => (
                    <Box
                        key={type_id}
                        onClick={() => onClick(type_id.toString())}
                        className={clsx(
                            classes.object,
                            store.filters.types.includes(type_id.toString()) ? classes.activeButton : classes.plainBackground,
                        )}
                        mr={1}
                        mb={1}
                    >
                        <Typography
                            variant="subtitle1"
                            className={clsx(
                                classes.buttonText,
                                store.filters.types.includes(type_id.toString()) && classes.activeButtonText,
                            )}
                        >
                            {type_name}
                        </Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
});

export default ApartmentsFilterObjectType;
