import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';

import { IconButton, SwipeableDrawer, MenuList, MenuItem, Box, makeStyles, ListItemIcon, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import BusinessIcon from '@material-ui/icons/Business';
import ApartmentIcon from '@material-ui/icons/Apartment';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
// import { CalculatorIcon } from '../../components/UI/Icons/SvgIcons';
// import Icon from '../../components/UI/Icons/Icon';

import { useStores } from '../../hooks/useStores';
import { CastForEducationOutlined, PeopleOutline } from '@material-ui/icons';

const MobileMenu = observer(() => {
    const classes = useStyles();
    const { ProfileStore } = useStores();
    const [open, setOpen] = useState(false);

    const handleCLickItem = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton size="small" onClick={() => setOpen(true)}>
                <MenuIcon />
            </IconButton>

            <SwipeableDrawer open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleCLickItem}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider />
                <Box p={2} pl={1} pr={3}>
                    <MenuList>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink exact to="/" activeClassName="selected">
                                <ListItemIcon>
                                    <HomeOutlinedIcon />
                                </ListItemIcon>
                                Главная
                            </NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/apartments" activeClassName="selected">
                                <ListItemIcon>
                                    <BusinessIcon />
                                </ListItemIcon>
                                Квартиры
                            </NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/residential-complexes" activeClassName="selected">
                                <ListItemIcon>
                                    <ApartmentIcon />
                                </ListItemIcon>
                                Жилые комплексы
                            </NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/my-clients" activeClassName="selected">
                                <ListItemIcon>
                                    <PeopleOutline />
                                </ListItemIcon>
                                Мои клиенты
                            </NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/applications-and-deals" activeClassName="selected">
                                <ListItemIcon>
                                    <QuestionAnswerOutlinedIcon />
                                </ListItemIcon>
                                Заявки/Сделки
                            </NavLink>
                        </MenuItem>
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/learning" activeClassName="selected">
                                <ListItemIcon>
                                    <CastForEducationOutlined />
                                </ListItemIcon>
                                Обучение
                            </NavLink>
                        </MenuItem>
                        {ProfileStore.userRole === 'director' && (
                            <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                                <NavLink to="/employees" activeClassName="selected">
                                    <ListItemIcon>
                                        <GroupOutlinedIcon />
                                    </ListItemIcon>
                                    Сотрудники
                                </NavLink>
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            <NavLink to="/feedback" activeClassName="selected">
                                <ListItemIcon>
                                    <PhoneOutlinedIcon />
                                </ListItemIcon>
                                Контакты
                            </NavLink>
                        </MenuItem>
                        {/*<MenuItem onClick={handleCLickItem} className={classes.menuItem}>*/}
                        {/*    <NavLink to="/documents" activeClassName="selected">*/}
                        {/*        <ListItemIcon>*/}
                        {/*            <Icon size={{ width: 24, height: 24 }} component={DocumentsIcon} />*/}
                        {/*        </ListItemIcon>*/}
                        {/*        Отчеты и документы*/}
                        {/*    </NavLink>*/}
                        {/*</MenuItem>*/}
                        <MenuItem onClick={handleCLickItem} className={classes.menuItem}>
                            {/* <NavLink to="/calculator" activeClassName="selected">
                                <ListItemIcon>
                                    <Icon size={{ width: 24, height: 24 }} component={CalculatorIcon} />
                                </ListItemIcon>
                                Ипотечный калькулятор
                            </NavLink> */}
                        </MenuItem>
                    </MenuList>
                </Box>
            </SwipeableDrawer>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 1),
        justifyContent: 'flex-start',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 40,
        '& a': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.text.primary,
            '&.selected': {
                color: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                },
            },
        },
    },
}));

export default MobileMenu;
