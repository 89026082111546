import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { AppBar, Container, Grid, Box, useTheme, useMediaQuery, Typography, makeStyles } from '@material-ui/core';

import logo from '../../img/logo_40.png';

import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';
import ProfileMenu from './ProfileMenu';
import FavoriteIcon from '@material-ui/icons/Favorite';

const Header = observer(({ isAuth }: { isAuth: boolean }) => {
    const classes = useStyles();
    const { pathname } = useLocation();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppBar position="sticky" color="inherit" className={classes.header}>
            <Container className={pathname === '/apartments' ? classes.containerWide : classes.containerRegular}>
                <Box my={isMD ? 1 : 2} overflow="hidden">
                    <Grid container justifyContent="space-between" alignItems="center" alignContent="center" wrap={'nowrap'}>
                        <Grid item container>
                            <Grid container alignItems="center" alignContent="center" wrap={'nowrap'}>
                                <Box mr={4}>
                                    {isMD ? (
                                        <MobileMenu />
                                    ) : (
                                        <Link to="/">
                                            <img src={logo} alt="" />
                                        </Link>
                                    )}
                                </Box>
                                {!isMD && <MainMenu />}
                            </Grid>
                        </Grid>

                        {isMD && isAuth && (
                            <Grid item className={classes.favorite}>
                                <NavLink to="/favorite" activeClassName="selected" className={classes.menuLinkIcon}>
                                    <FavoriteIcon />
                                </NavLink>
                            </Grid>
                        )}

                        <Grid item>
                            {isAuth ? (
                                <ProfileMenu />
                            ) : (
                                <Link to="/login">
                                    <Typography>Войти</Typography>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </AppBar>
    );
});

const useStyles = makeStyles(theme => ({
    containerRegular: {
        maxWidth: 1200,
    },
    containerWide: {
        maxWidth: 1920,
        padding: '0 40px',
    },
    header: {
        [theme.breakpoints.down('md')]: {
            ContentContent: 'center',
            height: 56,
        },
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.06)',
    },
    menuLinkIcon: {
        display: 'inline',
        //@ts-ignore
        color: theme.palette.primary.A200,
        '&:hover': {
            //@ts-ignore
            color: theme.palette.primary.A200,
        },
        '&.selected': {
            color: theme.palette.primary.main,
        },
    },
    favorite: {
        alignSelf: 'flex-end',
        marginRight: theme.spacing(2),
    },
}));

export default Header;
