import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    overflow: {
        overflowX: 'hidden',
    },
    leftCol: {
        [theme.breakpoints.down('md')]: {
            order: 2,
        },
    },
    rightCol: {
        [theme.breakpoints.down('md')]: {
            order: 1,
        },
    },
    address: {
        display: 'flex',
        alignItems: 'center',
    },
    mediumBlackColor: {
        color: theme.palette.text.secondary,
    },
    disabledBlackColor: {
        color: theme.palette.text.disabled,
    },
    apartmentDescriptionCol: {
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
    },
    bookBtn: {
        minWidth: 288,
        [theme.breakpoints.down('sm')]: {
            minWidth: 220,
        },
    },
    slider: {
        position: 'relative',
        marginBottom: 0,
        borderRadius: 8,
        border: '1px solid rgba(0, 0, 0, 0.08)',
        '& .slick-dots': {
            position: 'absolute',
            bottom: theme.spacing(6),
            left: 0,
            right: 0,
            [theme.breakpoints.down('md')]: {
                bottom: theme.spacing(4),
            },
            [theme.breakpoints.down('xs')]: {
                bottom: theme.spacing(2),
            },
            '& li button': {
                width: 8,
                height: 8,
                '&::before': {
                    fontSize: 8,
                    lineHeight: '8px',
                    width: 8,
                    height: 8,
                },
            },
        },
    },
    sliderItem: {
        display: 'flex !important',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 496,
        [theme.breakpoints.down('md')]: {
            minHeight: 460,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 400,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 270,
            height: '75vw',
            maxHeight: 496,
        },
        position: 'relative'
    },
    sliderImg: {
        maxWidth: 334,
        maxHeight: 334,
        [theme.breakpoints.down('sm')]: {
            maxWidth: 290,
            maxHeight: 290,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '68vw',
            maxHeight: '62vw',
        },
    },
    sliderPrevBtn: {
        '@media (max-width: 1200px)': {
            left: -25,
        },
        [theme.breakpoints.down('md')]: {
            left: 16,
        },
        [theme.breakpoints.down('xs')]: {
            left: 8,
        },
    },
    sliderNextBtn: {
        '@media (max-width: 1200px)': {
            right: -25,
        },
        [theme.breakpoints.down('md')]: {
            right: 16,
        },
        [theme.breakpoints.down('xs')]: {
            right: 8,
        },
    },
    zoomInBtn: {
        position: 'absolute',
        left: 40,
        top: 40,
        zIndex: 10000
    }
}));
