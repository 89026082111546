import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Typography, TextField, Grid, Button, useTheme, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import moment from 'moment';
import MuiModalRangePicker from '../UI/MuiModalRangePicker';
import { DateRange } from '@material-ui/pickers';

import { useStores } from '../../hooks/useStores';
import StatisticView from './StatisticView';
import { IEmployee } from '../../types/EmployeesTypes';

const Statistic = observer(({ type }: { type: string | null }) => {
    const { EmployeesStore, StatisticStore: store } = useStores();
    const [selectedDate, handleDateChange] = useState<DateRange>([moment().set('date', 1), moment()]);
    const [realtors, setRealtors] = useState([]);

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));

    const getParams = () => {
        let filterParams = '';

        if (selectedDate && selectedDate[0] && selectedDate[1]) {
            filterParams += `dateFrom=${moment(selectedDate[0]).format('YYYY-MM-DD')}`;
            filterParams += `&dateTo=${moment(selectedDate[1]).format('YYYY-MM-DD')}`;
        }
        if (realtors && realtors.length) {
            const realtorIds = realtors.map((realtor: IEmployee) => realtor.id);

            filterParams += `&realtors=${realtorIds.join(',')}`;
        }

        return filterParams;
    };

    //// Effects
    useEffect(() => {
        store.getStats('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (type === 'director') {
            EmployeesStore.getConfirmedEmployees(0, 20);
        }
        return () => {
            if (type === 'director') {
                EmployeesStore.resetEmployees();
            }
        };
    }, [EmployeesStore, store, type]);

    //// Handlers
    const handleSelectRealtors = (value: any) => {
        setRealtors(value);
    };

    const handleFilter = () => {
        store.getStats(getParams());
    };

    //// Renders
    const renderTitle = () => {
        let title;

        switch (type) {
            case 'realtor':
                title = 'Личная информация';
                break;
            case 'director':
                title = 'Ваше агентство';
                break;

            default:
                return null;
        }

        return (
            <Box mb={4}>
                <Typography variant="h2">{title}</Typography>
            </Box>
        );
    };

    const renderFilters = () => {
        return (
            <Grid container spacing={isMd ? 2 : 4} alignItems="flex-start">
                <Grid item xs={12} sm={6} lg={6}>
                    <Box mb={1}>
                        <Typography variant="body2">Риелтор</Typography>
                    </Box>
                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        value={realtors}
                        onChange={(e, value: any) => handleSelectRealtors(value)}
                        options={EmployeesStore.employees || []}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder={realtors.length ? 'Выбрать' : 'Все риелторы'}
                                InputProps={{ notched: false }}
                            />
                        )}
                        fullWidth={true}
                        limitTags={2}
                    />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box mb={1}>
                        <Typography variant="body2">Отчетный период</Typography>
                    </Box>
                    <MuiModalRangePicker date={selectedDate} onChange={handleDateChange} modalTitle="Выберите отчетный период" />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <Box mb={1}>
                        <Typography variant="body2">&nbsp;</Typography>
                    </Box>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleFilter}
                        fullWidth={isMd}
                        disabled={store.loading}
                    >
                        Показать
                    </Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <Box mb={{ xxs: 5, sm: 10 }}>
            {renderTitle()}
            {type === 'director' && renderFilters()}

            <Box mt={4}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <StatisticView data={store.stats?.deal || []} loading={store.loading} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StatisticView data={store.stats?.request || []} loading={store.loading} />

                        {store.stats?.agentSum ? (
                            <Box mt={4} p={3} border={1} borderRadius={16} borderColor="grey.200">
                                <Box mb={3}>
                                    <Typography variant="h2" component="p">
                                        {store.stats?.agentSum} ₽
                                    </Typography>
                                </Box>
                                <Typography>Сумма агентского вознаграждения</Typography>
                            </Box>
                        ) : (
                            <></>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
});

export default Statistic;
