import React from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { NavLink } from 'react-router-dom';

import HeaderMenu from './HeaderMenu';

const MainMenu = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const isSM = useMediaQuery(theme.breakpoints.down('sm'));
    const isLG = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <Grid container spacing={isSM ? 2 : isLG ? 3 : 4} wrap={'nowrap'} alignItems="center" className="main-menu">
            <Grid item>
                <NavLink exact to="/" activeClassName="selected" className={classes.menuLink}>
                    Главная
                </NavLink>
            </Grid>
            <Grid item>
                <NavLink to="/apartments" activeClassName="selected" className={classes.menuLink}>
                    Объекты
                </NavLink>
            </Grid>
            <Grid item>
                <NavLink to="/residential-complexes" activeClassName="selected" className={classes.menuLink}>
                    Жилые комплексы
                </NavLink>
            </Grid>

            <Grid item>
                <NavLink to="/my-clients" activeClassName="selected" className={classes.menuLink}>
                    Мои клиенты
                </NavLink>
            </Grid>

            <Grid item>
                <NavLink to="/applications-and-deals" activeClassName="selected" className={classes.menuLink}>
                    Заявки/Сделки
                </NavLink>
            </Grid>

            <Grid item>
                <NavLink to="/learning" activeClassName="selected" className={classes.menuLink}>
                    Обучение
                </NavLink>
            </Grid>

            <Grid item>
                <HeaderMenu />
            </Grid>

            <Grid item className={classes.favorite}>
                <NavLink to="/favorite" activeClassName="selected" className={classes.menuLinkIcon}>
                    <FavoriteIcon />
                </NavLink>
            </Grid>
        </Grid>
    );
});

const useStyles = makeStyles(theme => ({
    menuLink: {
        ...theme.typography.subtitle1,
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        display: 'inline-block',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        '&.selected': {
            color: theme.palette.primary.main,
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
        },
        whiteSpace: 'nowrap',
    },
    menuLinkIcon: {
        display: 'inline',
        //@ts-ignore
        color: theme.palette.primary.A200,
        '&:hover': {
            //@ts-ignore
            color: theme.palette.primary.A200,
        },
        '&.selected': {
            color: theme.palette.primary.main,
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
        },
    },
    favorite: {
        padding: '8px 16px 0px !important',
        marginLeft: 'auto',
        marginRight: theme.spacing(3),
        [theme.breakpoints.down('lg')]: {
            marginRight: theme.spacing(1),
        },
    },
}));

export default MainMenu;
