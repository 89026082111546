import React, { useState, useRef } from 'react';
import { Box, Grid, Typography, TextField, Button, Divider, InputAdornment, IconButton, Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';

import Autocomplete from '@material-ui/lab/Autocomplete';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { PhoneFormat, EmailFormat } from '../UI/Form';
import logo from '../../img/logo.png';
import { useStores } from '../../hooks/useStores';
import { config } from '../../Config';
import { inputMaskHelper } from '../../utils/inputMaskHelper';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

type IPassword = {
    [key: string]: boolean;
};

const Step1 = observer(({ store }: { store: any }) => {
    const { AgencyStore, SystemStore } = useStores();
    const inputPhone = useRef<any>(null);
    const [showPassword, setShowPassword] = useState<IPassword>({ password: false, confirmPassword: false });
    const values = store.registerData;
    const [agency, setAgency] = useState(null);

    const togglePassword = (key: string) => {
        setShowPassword({ ...showPassword, [key]: !showPassword[key] });
    };

    const handleChange = (event: any) => {
        event = inputMaskHelper(event);
        store.setValue(event.target.name, event.target.value);
    };

    const handleCheck = (value: boolean) => {
        store.setCheckAgreement(value);
    };

    const handleClick = (e: any) => {
        e.preventDefault();
        store.register();
    };

    const getOptions = (currValue: any, options: any) => {
        if (currValue?.value) {
            if (options) {
                if (!options.find((item: any) => item.value === currValue.value)) {
                    return [...options, currValue];
                }
            }

            return [currValue];
        }
        if (options) {
            return [...options];
        }

        return [];
    };

    const handleChangeAgency = (data: any) => {
        store.setAgency(data.value, data.data.inn);

        setAgency(data);
    };

    return (
        <>
            <Grid container justify="center">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </Grid>
            <Box mt={{ xxs: 1, xs: 4 }} mb={{ xxs: 3, xs: 8 }}>
                <Typography variant="h3" component="h1">
                    Регистрация
                </Typography>
            </Box>
            <form noValidate autoComplete="off">
                <TextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    name="name"
                    placeholder="Имя"
                    autoFocus
                    onChange={handleChange}
                    value={values.name}
                    error={!!store.signUpErrors?.name}
                    helperText={store.signUpErrors?.name}
                    disabled={store.loading}
                    InputProps={{ notched: false }}
                />
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        name="surname"
                        placeholder="Фамилия"
                        autoFocus
                        onChange={handleChange}
                        value={values.surname}
                        error={!!store.signUpErrors?.surname}
                        helperText={store.signUpErrors?.surname}
                        disabled={store.loading}
                        InputProps={{ notched: false }}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        fullWidth
                        name="lastname"
                        placeholder="Отчество (обязательно при наличии)"
                        autoFocus
                        onChange={handleChange}
                        value={values.lastname}
                        error={!!store.signUpErrors?.lastname}
                        helperText={store.signUpErrors?.lastname}
                        disabled={store.loading}
                        InputProps={{ notched: false }}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        name="email"
                        placeholder="example@mail.ru"
                        InputProps={{
                            inputComponent: EmailFormat,
                            notched: false,
                        }}
                        onChange={handleChange}
                        value={values.email}
                        error={!!store.signUpErrors?.email}
                        helperText={store.signUpErrors?.email}
                        disabled={store.loading}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        name="phone"
                        placeholder={config.phonePlaceholderWithZero}
                        InputProps={{
                            inputComponent: PhoneFormat,
                            notched: false,
                        }}
                        onChange={handleChange}
                        inputRef={inputPhone}
                        onFocus={() => {
                            setTimeout(() => {
                                inputPhone?.current?.setSelectionRange(3, 3);
                            }, 0);
                        }}
                        value={values.phone}
                        error={!!store.signUpErrors?.phone}
                        helperText={store.signUpErrors?.phone}
                        disabled={store.loading}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <Autocomplete
                        value={agency || null}
                        options={getOptions(agency, AgencyStore?.agency)}
                        getOptionLabel={(option: any) => `${option.value} (ИНН ${option.data.inn})`}
                        getOptionSelected={(option: any, value: any) => option.value === value.value}
                        onChange={(e, value) => handleChangeAgency(value)}
                        loadingText="Поиск..."
                        noOptionsText="Не найдено"
                        className="input-wrap"
                        disableClearable={true}
                        renderInput={(params: any) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                margin="none"
                                placeholder="Агентство"
                                name="agency"
                                fullWidth
                                onChange={e => AgencyStore.getAgency(e.target.value)}
                                error={!!store.signUpErrors?.agency}
                                helperText={store.signUpErrors?.agency}
                                disabled={store.loading}
                            />
                        )}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        name="password"
                        type={showPassword['password'] ? 'text' : 'password'}
                        placeholder="Пароль"
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => togglePassword('password')}>
                                        {showPassword['password'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                        onChange={handleChange}
                        value={values.password}
                        error={!!store.signUpErrors?.password}
                        helperText={store.signUpErrors?.password}
                        disabled={store.loading}
                    />
                </Box>
                <Box mt={4} mb={4}>
                    <TextField
                        variant="outlined"
                        margin="none"
                        required
                        fullWidth
                        name="confirmPassword"
                        type={showPassword['confirmPassword'] ? 'text' : 'password'}
                        placeholder="Подтвердите пароль"
                        InputProps={{
                            autoComplete: 'new-password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={() => togglePassword('confirmPassword')}>
                                        {showPassword['confirmPassword'] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            notched: false,
                        }}
                        onChange={handleChange}
                        value={values.confirmPassword}
                        error={!!store.signUpErrors?.confirmPassword}
                        helperText={store.signUpErrors?.confirmPassword}
                        disabled={store.loading}
                    />
                </Box>

                <Box mt={4} mb={2}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleClick}
                        disabled={store.loading || !store.registerData.checkAgreements}
                    >
                        Зарегистрироваться
                    </Button>
                </Box>
                <Box mb={2}>
                    <Typography>
                        Уже зарегистрированы? <Link to="/login">Войдите</Link>
                    </Typography>
                </Box>
                <Divider />
                <Box mt={2} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                    <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={store.registerData.checkAgreements}
                        onChange={(e: any, checked: boolean) => handleCheck(checked)}
                    />
                    <Box textAlign="left">
                        <Typography variant="caption">
                            Выражаю согласие на обработку{' '}
                            <a href={SystemStore.links?.['personal'] || '#'} target="_blank" rel="noopener noreferrer">
                                <Typography variant="inherit" component={'span'} color="primary">
                                    персональных данных
                                </Typography>
                                {', '}
                            </a>
                            присоединяюсь к условиям{' '}
                            <a href={SystemStore.links?.['offer'] || '#'} target="_blank" rel="noopener noreferrer">
                                <Typography variant="inherit" component={'span'} color="primary">
                                    Договора-оферты
                                </Typography>{' '}
                            </a>
                            и{' '}
                            <a href={SystemStore.links?.['policy'] || '#'} target="_blank" rel="noopener noreferrer">
                                <Typography variant="inherit" component={'span'} color="primary">
                                    Политики конфиденциальности
                                </Typography>{' '}
                            </a>
                        </Typography>
                    </Box>
                </Box>
            </form>
        </>
    );
});

export default Step1;
