import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';

import TextFieldWithoutHelper from '../../UI/TextFieldWithoutHelper';

const ApartmentsFilterObjectNumber = observer(({ onChange }: { onChange: any }) => {
    const { ApartmentStore: store } = useStores();

    return (
        <Box width={{ xxs: 256, xs: 145, sm: 256 }} mr={{ xxs: 1, sm: 2 }} mb={{ xxs: 1, sm: 2 }}>
            <TextFieldWithoutHelper
                fullWidth
                name="objectNumber"
                variant="outlined"
                placeholder="Номер помещения"
                value={store.filters.objectNumber}
                onChange={onChange}
            />
        </Box>
    );
});

export default ApartmentsFilterObjectNumber;
