import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Typography, useTheme, useMediaQuery } from '@material-ui/core';

import DocumentItemForm from './DocumentItemForm';
import { useStores } from '../../../hooks/useStores';
// import { useStyles } from '../style';

const AppDealDocsForm = observer(({ store }: any) => {
    const { AppDealFormStore: storeGlobal } = useStores();
    // const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <Box mb={{ xxs: 2, md: 4 }}>
                <Box mb={2}>
                    <Typography variant="body2">Фото паспорта</Typography>
                </Box>

                <Grid container spacing={isXS ? 1 : 2}>
                    <Grid item xs={12} sm={6} lg={5}>
                        <DocumentItemForm
                            objKey="passportScanMain"
                            defaultFilePath={storeGlobal.appDeal?.client?.file_passport_main || ''}
                            defaultFileUuid={storeGlobal.appDeal?.client?.passportScanMainUuid || ''}
                            store={store}
                        >
                            Основной разворот паспорта
                        </DocumentItemForm>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={5}>
                        <DocumentItemForm
                            objKey="passportScanOther"
                            defaultFilePath={storeGlobal.appDeal?.client?.file_passport_registration || ''}
                            defaultFileUuid={storeGlobal.appDeal?.client?.passportScanOtherUuid || ''}
                            store={store}
                        >
                            Страница с пропиской
                        </DocumentItemForm>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={isXS ? 1 : 2}>
                <Grid item xs={12} sm={6} lg={5}>
                    <Box width="100%" mb={{ xxs: 2, md: 4 }}>
                        <Box mb={2}>
                            <Typography variant="body2">ИНН</Typography>
                        </Box>
                        <Grid container spacing={isXS ? 1 : 2}>
                            {/* скрыта в рамках задачи ABD-720
                                <Grid item xs={12} sm={5} className={classes.paddingBottom}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="inn"
                                        label="Номер ИНН (12 цифр)"
                                        value={store.values.inn}
                                        onChange={store.handleChangeForm}
                                        InputProps={{ inputComponent: InnMaskFormat, notched: false }}
                                        disabled={store.loading}
                                        // error={store.formErrors?.inn}
                                        // helperText={store.formErrors?.inn && 'Введите ИНН'}
                                        // FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                    />
                                </Grid>
                             */}

                            <Grid item xs={12}>
                                <DocumentItemForm
                                    objKey="innScan"
                                    defaultFilePath={storeGlobal.appDeal?.client?.file_inn || ''}
                                    defaultFileUuid={storeGlobal.appDeal?.client?.innScanUuid || ''}
                                    store={store}
                                >
                                    Скан ИНН
                                </DocumentItemForm>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={5}>
                    <Box width="100%" mb={{ xxs: 2, md: 3 }}>
                        <Box mb={2}>
                            <Typography variant="body2">СНИЛС</Typography>
                        </Box>
                        <Grid container spacing={isXS ? 1 : 2}>
                            {/* скрыта в рамках задачи ABD-720
                                <Grid item xs={12} sm={5} className={classes.paddingBottom}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        name="snils"
                                        label="Номер СНИЛС (11 цифр)"
                                        value={store.values.snils}
                                        onChange={store.handleChangeForm}
                                        InputProps={{ inputComponent: SnilsMaskFormat, notched: false }}
                                        disabled={store.loading}
                                        // error={store.formErrors?.snils}
                                        // helperText={store.formErrors?.snils && 'Введите СНИЛС'}
                                        // FormHelperTextProps={{ classes: { root: classes.errorFormTextInput } }}
                                    />
                                </Grid>
                            */}

                            <Grid item xs={12}>
                                <DocumentItemForm
                                    objKey="snilsScan"
                                    defaultFilePath={storeGlobal.appDeal?.client?.file_snils || ''}
                                    defaultFileUuid={storeGlobal.appDeal?.client?.snilsScanUuid || ''}
                                    store={store}
                                >
                                    Скан СНИЛС
                                </DocumentItemForm>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            {store.activeClientTab === 'over18Married' && storeGlobal.activePropertyTab !== 'together' && (
                <Box mb={{ xxs: 2, md: 4 }}>
                    <Grid item xs={12} sm={5}>
                        <Box mb={2}>
                            <Typography variant="body2">
                                Нотариальное согласие другого супруга {storeGlobal.activePropertyTab === 'part' && '(Необязательно)'}
                            </Typography>
                        </Box>

                        <DocumentItemForm
                            objKey="notarialScan"
                            defaultFilePath={storeGlobal.appDeal?.client?.file_notarial_scan || ''}
                            defaultFileUuid={storeGlobal.appDeal?.client?.notarialScanUuid || ''}
                            store={store}
                        >
                            Скан нотариального согласия
                        </DocumentItemForm>
                    </Grid>
                </Box>
            )}

            {store.activeClientTab === 'over18Married' && (
                <Box mb={{ xxs: 2, md: 4 }}>
                    <Grid item xs={12} sm={5}>
                        <Box mb={2} mt={2.5}>
                            <Typography variant="body2">Брачный договор (необязательно)</Typography>
                        </Box>

                        <DocumentItemForm
                            objKey="marriageScan"
                            defaultFilePath={storeGlobal.appDeal?.client?.file_marriage_scan || ''}
                            defaultFileUuid={storeGlobal.appDeal?.client?.marriageScanUuid || ''}
                            store={store}
                        >
                            Скан брачного договора
                        </DocumentItemForm>
                    </Grid>
                </Box>
            )}

            {store.activeClientTab === 'over14' && (
                <Box mb={{ xxs: 2, md: 4 }}>
                    <Box my={2}>
                        <Typography variant="body2">Согласие законного представителя / одного из родителей</Typography>
                    </Box>

                    <Grid item xs={12} sm={6}>
                        <DocumentItemForm
                            objKey="agreeParentScan"
                            defaultFilePath={storeGlobal.appDeal?.client?.file_agree_parent_scan || ''}
                            defaultFileUuid={storeGlobal.appDeal?.client?.agreeParentScanUuid || ''}
                            store={store}
                        >
                            Скан согласия
                        </DocumentItemForm>
                    </Grid>
                </Box>
            )}
        </>
    );
});

export default AppDealDocsForm;
