import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import { Container, Box, Grid, Typography, Button, Divider, useTheme, useMediaQuery, IconButton, Fab } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LockIcon from '@material-ui/icons/Lock';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import CarouselArrow from '../../components/CarouselArrow/CarouselArrow';
import TextButton from '../../components/UI/TextButton';
import DottedListItem from '../../components/UI/DottedListItem';
import RelatedObjectsCarousel from '../../components/Apartment/RelatedObjectsCarousel';
import RequestError from '../../components/RequestError/RequestError';
import Loader from '../../components/Loader/Loader';
import EmptyImage from '../../components/UI/EmptyImage';
import DownloadIcon from '../../components/UI/DownloadIcon';
import BookApartment from '../../components/Apartment/BookApartment';

import { useStores } from '../../hooks/useStores';
import { useStyles } from './style';
import { ZoomIn } from '@material-ui/icons';
import { ImageViewer } from '../../components/Apartment/ImageViewer';

const Apartment = observer(() => {
    const classes = useStyles();
    const history = useHistory();
    const { apartmentId } = useParams();
    const { ApartmentStore: store, ProfileStore } = useStores();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isXXS = !useMediaQuery(theme.breakpoints.up('xs'));

    const [imageViewerLink, setImageViewerLink] = useState<string | null>(null);

    const sliderSettings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CarouselArrow type="prev" addClassName={classes.sliderPrevBtn} />,
        nextArrow: <CarouselArrow type="next" addClassName={classes.sliderNextBtn} />,
    };

    useEffect(() => {
        store.getApartment(apartmentId);
    }, [apartmentId, store]);

    //// Handlers
    const handleBackToCatalog = () => {
        history.length === 1 ? history.push('/apartments') : history.goBack();
    };

    const handleSetImageViewer = (src: string | null) => {
        setImageViewerLink(src);
    };

    const handleResetImageViewer = () => {
        setImageViewerLink(null);
    };

    //// Renders

    const renderBookBtn = () => {
        if (store.apartment?.data.is_booked) {
            return (
                <Box mb={6}>
                    <Button color="primary" variant="contained" size="large" className={classes.bookBtn} disabled startIcon={<LockIcon />}>
                        Забронирована
                    </Button>
                </Box>
            );
        } else {
            if (ProfileStore.userRole === 'director' || ProfileStore.userRole === 'realtor') {
                return (
                    <Box mb={6}>
                        <BookApartment data={store?.apartment?.data} />
                    </Box>
                );
            }
        }

        return null;
    };

    const renderContent = () => {
        if (store.apartment?.success) {
            const {
                images,
                complex,
                rooms_number,
                object_number,
                total_area,
                priceFormat,
                address,
                living_space,
                kitchen_space,
                floor_number,
                max_floor,
                house_number,
                development_end,
                window_view,
                similar,
                type_id,
                type_name,
            } = store.apartment.data;

            return (
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={6} className={classes.leftCol}>
                            <Box mt={{ xxs: 2, md: 3 }} mb={2}>
                                <Typography variant="body2" color="textSecondary">
                                    {complex}
                                </Typography>
                            </Box>
                            <Typography variant={isXXS ? 'h4' : 'h3'}>
                                {type_id === '2' ? !!rooms_number && `${rooms_number}-комн. квартира` : type_name} №{object_number} /
                                {total_area} м²
                            </Typography>
                            <Box mb={1}>
                                <Typography variant={isXXS ? 'h3' : 'h2'}>{priceFormat}</Typography>
                            </Box>
                            <Box mb={6} className={clsx(classes.address, classes.mediumBlackColor)}>
                                <LocationOnOutlinedIcon color="inherit" />
                                <Typography variant="body2" color="inherit">
                                    {address}
                                </Typography>
                            </Box>

                            {renderBookBtn()}

                            <Grid container spacing={3}>
                                {<ApartmentAttributes title={total_area ? `${total_area} м²` : '-'} desc="Общая площадь" />}
                                {<ApartmentAttributes title={living_space ? `${living_space} м²` : '-'} desc="Жилая площадь" />}
                                {<ApartmentAttributes title={kitchen_space ? `${kitchen_space} м²` : '-'} desc="Кухня" />}
                                {<ApartmentAttributes title={floor_number ? `${floor_number} / ${max_floor || ''}` : '-'} desc="Этаж" />}
                                {<ApartmentAttributes title={house_number ? house_number : '-'} desc="Корпус" />}
                                {
                                    <ApartmentAttributes
                                        title={development_end ? development_end.replace('года', '') : 'Уточняется'}
                                        desc="Срок сдачи"
                                    />
                                }
                            </Grid>

                            <Box my={4}>
                                <Divider />
                            </Box>

                            <Box mb={3}>
                                <Typography variant="h4">Дополнительные параметры</Typography>
                            </Box>
                            <DottedListItem leftContent="Окна смотрят" rightContent={window_view ? window_view : 'уточняется'} />

                            <Box my={4}>
                                <Divider />
                            </Box>
                        </Grid>

                        <Grid item xs={12} lg={6} className={classes.rightCol}>
                            <Slider {...sliderSettings} className={classes.slider}>
                                {images.length ? (
                                    images.map((imgPath: string, index: number) => (
                                        <Box key={index} className={classes.sliderItem}>
                                            <img src={imgPath} alt="" className={classes.sliderImg} />
                                            <Fab
                                                color="secondary"
                                                size={isXS ? 'small' : 'medium'}
                                                className={classes.zoomInBtn}
                                                onClick={() => handleSetImageViewer(imgPath)}
                                            >
                                                <ZoomIn />
                                            </Fab>
                                        </Box>
                                    ))
                                ) : (
                                    <Box className={classes.sliderItem}>
                                        <EmptyImage variant="apartment" />
                                    </Box>
                                )}
                            </Slider>
                        </Grid>
                    </Grid>

                    <Box>
                        {/*<Box mb={3}>*/}
                        {/*    <Typography variant="h4">О квартире</Typography>*/}
                        {/*</Box>*/}
                        {/*<Grid container spacing={4}>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Комната - 1" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Санузел - 2" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Балкон - 2" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Комната - 1" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Прихожая" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Кладовая" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Комната - 1" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Балкон - 1" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Подъезд" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={12} sm={6} md={4} className={classes.apartmentDescriptionCol}>*/}
                        {/*        <DottedListItem leftContent="Санузел - 1" rightContent="17 м²" dottedLine/>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}

                        <Box mt={6} mb={4}>
                            <Divider />
                        </Box>

                        <RelatedObjectsCarousel data={similar} />
                    </Box>
                </>
            );
        } else if (!store.apartment?.success) {
            return <RequestError />;
        }

        return <div />;
    };

    if (store.loading) {
        return <Loader />;
    }

    return (
        <>
            <Container maxWidth="xl">
                <Box my={{ xxs: 4, md: 6 }} mb={{ xxs: 7, md: 9 }}>
                    <Box mb={{ xxs: 2, sm: 3 }} display="flex" justifyContent="space-between">
                        <TextButton color="primary" startIcon={<ArrowBackOutlinedIcon />} onClick={handleBackToCatalog}>
                            К каталогу
                        </TextButton>
                        {isXS ? (
                            <Box>
                                {store.apartment?.data.isFavorite ? (
                                    <IconButton color="primary" onClick={store.removeApartmentFromFavorite}>
                                        <FavoriteIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton color="primary" onClick={store.addApartmentToFavorite}>
                                        <FavoriteBorderIcon />
                                    </IconButton>
                                )}
                                <IconButton
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        store.apartment && store.downloadObject([store.apartment.data.object_id]);
                                    }}
                                >
                                    <ShareOutlinedIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <Box display="flex">
                                <Box
                                    onClick={() => {
                                        store.apartment && store.downloadObject([store.apartment.data.object_id]);
                                    }}
                                >
                                    <TextButton color="primary" startIcon={<DownloadIcon color="primary" />}>
                                        Поделиться
                                    </TextButton>
                                </Box>
                                <Box ml={4}>
                                    {store.apartment?.data.isFavorite ? (
                                        <TextButton
                                            color="primary"
                                            startIcon={<FavoriteIcon color="primary" />}
                                            onClick={store.removeApartmentFromFavorite}
                                        >
                                            Убрать из избранного
                                        </TextButton>
                                    ) : (
                                        <TextButton
                                            color="primary"
                                            startIcon={<FavoriteBorderIcon color="primary" />}
                                            onClick={store.addApartmentToFavorite}
                                        >
                                            Добавить в избранное
                                        </TextButton>
                                    )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                    {renderContent()}
                </Box>
            </Container>
            <ImageViewer open={!!imageViewerLink} src={imageViewerLink} onClose={handleResetImageViewer} />
        </>
    );
});

const ApartmentAttributes = ({ title, desc }: { title: string; desc: string }) => {
    const classes = useStyles();

    return (
        <Grid item xs={6} sm={4}>
            <Typography variant="h4">{title}</Typography>
            <Typography variant="body2" className={classes.disabledBlackColor}>
                {desc}
            </Typography>
        </Grid>
    );
};

export default Apartment;
