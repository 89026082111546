import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { IAppDealInstallmentItem } from '../../../types/AppDealTypes';
import { useStyles } from '../style';
import { useStores } from '../../../hooks/useStores';
import { MoneyInput } from '../../UI/Form';

const AppDealPaymentInstallment = observer(() => {
    const { AppDealFormStore: store } = useStores();
    const classes = useStyles();
    const theme = useTheme();
    const isXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box mb={3}>
            <Box mb={4}>
                <Typography variant="subtitle1">Заполните данные для рассрочки</Typography>
            </Box>

            <Grid container justify="space-between">
                <Grid item xs={12} sm={6} style={{ order: isXS ? 2 : 1, marginTop: isXS ? 16 : 0 }}>
                    <Typography variant="body2" color="textSecondary">
                        Стоимость квартиры
                    </Typography>
                    <Typography variant="h3">{store.appDeal?.object.price_full}</Typography>

                    <Box my={2}>
                        <Autocomplete
                            limitTags={1}
                            id="installment"
                            popupIcon={<ExpandMoreIcon />}
                            onOpen={store.getInstallmentLength}
                            onChange={(e, value: any) => store.setInstallmentCode(value || '')}
                            options={store.installmentItems}
                            getOptionLabel={(option: IAppDealInstallmentItem) => option.name}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    name="installmentLength"
                                    variant="outlined"
                                    placeholder="Срок рассрочки, месяцев"
                                    error={!!store.formErrors?.installmentLength}
                                    helperText={store.formErrors?.installmentLength}
                                />
                            )}
                            noOptionsText="Нет подходящих вариантов"
                            loadingText="Загрузка"
                        />
                    </Box>

                    <TextField
                        fullWidth
                        variant="outlined"
                        name="installmentFirstPayment"
                        label="Первоначальный взнос, ₽ (50% и более)"
                        value={store.values.installmentFirstPayment}
                        onChange={store.handleInstallmentDataChange}
                        InputProps={{ inputComponent: MoneyInput, notched: false }}
                        error={!!store.formErrors?.installmentFirstPayment}
                        helperText={store.formErrors?.installmentFirstPayment}
                    />

                    <Box mt={3} mb={2}>
                        <FormControlLabel
                            className={classes.label}
                            control={
                                <Checkbox
                                    checked={store.values.personalDataAgreement}
                                    onChange={store.toggleAgreementCheckbox}
                                    name="personalDataAgreement"
                                />
                            }
                            label={<Typography variant="body2">Согласие на обработку персональных данных</Typography>}
                        />
                    </Box>

                    <Typography variant="caption" color="textSecondary">
                        Отправляя эту заявку, вы подтверждаете отправку заявления на рассрочку для согласования Ак Барс Дома
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={5} style={{ order: isXS ? 1 : 2 }}>
                    <Box p={3} className={classes.payment}>
                        <Typography variant="subtitle1">Ежемесячный платеж</Typography>
                        <Box mb={1}>
                            <Typography variant="h5">
                                <NumberFormat value={store.monthlyPayment} thousandSeparator=" " isNumericString displayType="text" />
                            </Typography>
                        </Box>

                        <Typography variant="subtitle2">Дата погашения рассрочки</Typography>
                        <Typography variant="h6">{store.installmentEndDate}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
});

export default AppDealPaymentInstallment;
