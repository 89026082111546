import React from 'react';
import { observable, action, computed } from 'mobx';
import {
    IAppDealDocs,
    IAppDealFormDataClientDocs,
    IAppDealClient,
    IAppDealClientMainInfo,
    IAppDealClientPassport,
    IAppDealParticipant,
    IAppDealClientType,
} from '../types/AppDealTypes';
import API from '../api';
import { stores } from '../contexts';
import { formatDevEnd } from '../utils/formatDevEnd';
import { inputMaskHelper } from '../utils/inputMaskHelper';

const initFormData: IAppDealClient = {
    contactId: null,
    mainInfo: {
        firstname: '',
        lastname: '',
        surname: '',
        phone: '',
        email: '',
    },
    passport: {
        series: '',
        number: '',
        issuedBy: '',
        dateOfIssue: '',
        placeOfResidence: '',
        unitCode: '',
        birthDate: '',
        birthPlace: '',
    },

    files: {
        agreeParentScan: { scanType: 566658, file: '' },
        marriageScan: { scanType: 489531, file: '' },
        notarialScan: { scanType: 489533, file: '' },
        passportScanMain: { scanType: 489515, file: '' },
        passportScanOther: { scanType: 489517, file: '' },
        innScan: { scanType: 440954, file: '' },
        snilsScan: { scanType: 489521, file: '' },
        birthCertScan: { scanType: 489527, file: '' },
    },
    inn: '',
    snils: '',
    birthCertSerial: '',
    birthCertNumber: '',
    propertyPercent: null,
    depositor: false,
};

export class AppDealFormClientStore {
    @observable loading: boolean = false;
    @observable loadingAddress: boolean = false;

    @observable values: IAppDealClient = initFormData;

    @observable activeClientTab: IAppDealClientType = stores.AppDealFormStore.activePropertyTab === 'together' ? 'over18Married' : '';

    @observable showChangeClientModal: boolean = false;

    @observable proceedToNewClient: any = null;

    @observable isFilled: null | 0 | string | boolean = false;

    @observable formErrors: any = {};

    @observable uploadedFilesUuid: IAppDealFormDataClientDocs = {
        passportScanMainUuid: '',
        passportScanOtherUuid: '',
        innScanUuid: '',
        snilsScanUuid: '',
        birthCertScanUuid: '',
        agreeParentScanUuid: '',
        notarialScanUuid: '',
        marriageScanUuid: '',
    };

    @observable modalMessage: string = '';

    @observable addressAutocomplete: string[] = [];

    @action uploadFiles = async () => {
        const promises = Object.keys(this.values.files).map((key: string) => {
            if (Object.prototype.hasOwnProperty.call(this.values.files, key)) {
                const fileObjKey = key as keyof IAppDealDocs;
                const file = this.values.files[fileObjKey].file;

                const uuidKey = `${fileObjKey}Uuid` as keyof IAppDealFormDataClientDocs;

                if (!this.uploadedFilesUuid[uuidKey]?.length && file) {
                    const fileData = new FormData();

                    // @ts-ignore
                    //  fileData.append('entityId', this.values.contactId);
                    fileData.append('scanType', this.values.files[fileObjKey].scanType.toString());
                    fileData.append('file', file);

                    //this.fileUploading = true;

                    return API.appDeals
                        .uploadFile(fileData)
                        .then(response => {
                            if (response?.data?.success) {
                                this.uploadedFilesUuid[uuidKey] = response.data.data?.uuid || '';
                            }
                        })
                        .catch(error => {
                            this.formErrors = error.response?.data.errors;
                        })
                        .finally(() => {
                            //this.fileUploading = false;
                        });
                }
            }

            return null;
        });

        return await Promise.all(promises || []);
    };

    @action setInitialClientData = (client: IAppDealParticipant) => {
        this.values.contactId = client.id;
        this.values.mainInfo.firstname = client.firstname;
        this.values.mainInfo.lastname = client.lastname;
        this.values.mainInfo.surname = client.surname;
        this.values.mainInfo.email = client.email;
        this.values.mainInfo.phone = client.phone;
        this.values.passport.number = client.passport_number;
        this.values.passport.series = client.passport_series;
        this.values.passport.placeOfResidence = client.address;
        this.values.passport.birthPlace = client.birthPlace;
        this.values.passport.birthDate = client.birthday.split('-').reverse().join('.');
        this.values.passport.dateOfIssue = client.passport_date_of_issue.split('-').reverse().join('.');
        this.values.passport.issuedBy = client.passport_issued;
        this.values.passport.unitCode = client.passport_department_code;
        this.values.depositor = !!client.depositor;
        this.values.inn = client.inn;
        this.values.snils = client.snils_number;
        this.values.birthCertSerial = client.birthCertSerial;
        this.values.birthCertNumber = client.birthCertNumber;
        this.values.files.innScan.file = client.file_inn;
        this.values.files.passportScanMain.file = client.file_passport_main;
        this.values.files.passportScanOther.file = client.file_passport_registration;
        this.values.files.snilsScan.file = client.file_snils;
        this.values.files.birthCertScan.file = client.file_birth_cert_scan;
        this.values.files.marriageScan.file = client.file_marriage_scan;
        this.values.files.agreeParentScan.file = client.file_agree_parent_scan;
        this.values.files.notarialScan.file = client.file_notarial_scan;
        this.uploadedFilesUuid.passportScanMainUuid = client.passportScanMainUuid;
        this.uploadedFilesUuid.passportScanOtherUuid = client.passportScanOtherUuid;
        this.uploadedFilesUuid.snilsScanUuid = client.snilsScanUuid;
        this.uploadedFilesUuid.innScanUuid = client.innScanUuid;
        this.uploadedFilesUuid.agreeParentScanUuid = client.agreeParentScanUuid;
        this.uploadedFilesUuid.marriageScanUuid = client.marriageScanUuid;
        this.uploadedFilesUuid.notarialScanUuid = client.notarialScanUuid;
        this.uploadedFilesUuid.birthCertScanUuid = client.birthCertScanUuid;
    };

    @action
    setClientToOver18Married = () => (this.activeClientTab = 'over18Married');

    validateParticipantForm = (): boolean => {
        this.validateMainInfo();
        this.validateClient();

        stores.AppDealFormStore.activePropertyTab === 'part' && this.validateValue('propertyPercent');

        switch (this.activeClientTab) {
            case 'over18Single' || '': {
                this.validatePassport();
                this.validateInn();
                this.validateSnils();
                break;
            }
            case 'over18Married': {
                this.validatePassport();
                this.validateInn();
                this.validateSnils();
                stores.AppDealFormStore.activePropertyTab === 'personal' && this.validateNotarialFile();
                break;
            }
            case 'over14': {
                this.validatePassport();
                this.validateInn();
                this.validateSnils();
                this.validateParentAgreementFile();
                break;
            }
            case 'under14': {
                this.validateBirthCert();
                this.validateParentAgreementFile();
                break;
            }
        }
        return Object.values(this.formErrors).some(item => item);
    };

    validateParticipantPassportScans = (): boolean => {
        this.validatePassport();
        return this.formErrors;
    };

    validateClient = (): void => {
        if (!this.activeClientTab) {
            this.formErrors['client'] = true;
        }
    };

    validateMainInfo = (): void => {
        Object.entries(this.values.mainInfo).forEach(([key, value]) => {
            if (!value) {
                this.formErrors[key] = true;
            }
        });
    };

    validatePassport = (): void => {
        // Object.entries(this.values.passport).forEach(([key, value]) => {
        //     if (!value) {
        //         this.formErrors[key] = true;
        //     }
        // });
        this.validateFile('passportScanMain');
        this.validateFile('passportScanOther');
    };

    validateInn = (): void => {
        // this.validateValue('inn');
        this.validateFile('innScan');
    };

    validateSnils = (): void => {
        //this.validateValue('snils');
        this.validateFile('snilsScan');
    };

    validateBirthCert = (): void => {
        // this.validateValue('birthCertSerial');
        // this.validateValue('birthCertNumber');
        this.validateFile('birthCertScan');
    };

    validateNotarialFile = (): void => {
        this.validateFile('notarialScan');
    };

    validateMarriageAgreementFile = (): void => {
        this.validateFile('marriageScan');
    };

    validateParentAgreementFile = (): void => {
        this.validateFile('agreeParentScan');
    };

    validateValue = (value: string): void => {
        if (!this.values[value as keyof IAppDealClient]) {
            this.formErrors[value] = true;
        }
    };

    validateFile = (name: string): void => {
        if (
            !this.values.files[name as keyof IAppDealDocs].file ||
            (this.values.files[name as keyof IAppDealDocs].file?.hasOwnProperty('name') &&
                this.values.files[name as keyof IAppDealDocs].file?.name === undefined)
        ) {
            this.formErrors[name] = true;
        }
    };

    @action
    checkIfFilled = () => {
        this.isFilled = this.checkFields;
        stores.AppDealFormStore.checkIfClientFilled();
    };

    @computed
    get checkFields() {
        const isFilled: boolean[] = [];

        switch (this.activeClientTab) {
            case 'over18Single': {
                isFilled.push(this.isMainInfoFilled());
                // isFilled.push(this.isPassportFilled());
                isFilled.push(this.isPassportFilesFilled());
                isFilled.push(this.isInnFilled());
                isFilled.push(this.isSnilsFilled());
                stores.AppDealFormStore.activePropertyTab === 'part' && isFilled.push(this.isPropertyPercentFilled());
                break;
            }
            case 'over18Married': {
                isFilled.push(this.isMainInfoFilled());
                //isFilled.push(this.isPassportFilled());
                isFilled.push(this.isPassportFilesFilled());
                isFilled.push(this.isInnFilled());
                isFilled.push(this.isSnilsFilled());
                stores.AppDealFormStore.activePropertyTab === 'personal' && isFilled.push(this.isSpouseAgreementFilled());
                stores.AppDealFormStore.activePropertyTab === 'part' && isFilled.push(this.isPropertyPercentFilled());
                break;
            }
            case 'over14': {
                isFilled.push(this.isMainInfoFilled());
                // isFilled.push(this.isPassportFilled());
                isFilled.push(this.isPassportFilesFilled());
                isFilled.push(this.isInnFilled());
                isFilled.push(this.isSnilsFilled());
                isFilled.push(this.isParentAgreementFilled());
                stores.AppDealFormStore.activePropertyTab === 'part' && isFilled.push(this.isPropertyPercentFilled());
                break;
            }
            case 'under14': {
                isFilled.push(this.isMainInfoFilled());
                isFilled.push(this.isBirthCertificateFilled());
                isFilled.push(this.isParentAgreementFilled());
                break;
            }
            default:
                isFilled.push(this.isMainInfoFilled());
        }
        return isFilled.every(item => item);
    }

    isMainInfoFilled = (): boolean => Object.values(this.values.mainInfo).every(item => item);

    isPassportFilled = (): boolean => Object.values(this.values.passport).every(item => item);

    isPassportFilesFilled = (): boolean => !!this.values.files.passportScanMain.file && !!this.values.files.passportScanOther.file;

    isInnFilled = (): boolean => !!this.values.files.innScan.file;

    isSnilsFilled = (): boolean => !!this.values.files.snilsScan.file;

    isBirthCertificateFilled = (): boolean => !!this.values.files.birthCertScan.file;

    isSpouseAgreementFilled = (): boolean => !!this.values.files.notarialScan.file;

    isParentAgreementFilled = (): boolean => !!this.values.files.agreeParentScan.file;

    isPropertyPercentFilled = (): boolean => !!this.values.propertyPercent;

    @action
    setActiveClientTab = (name: IAppDealClientType) => {
        if (name === this.activeClientTab) return;

        if (name === 'under14' || this.activeClientTab === 'under14') {
            const isPassportValues = Object.values(this.values.passport).some(item => item);
            const isFiles = Object.values(this.values.files).some(({ file }) => file);
            const otherValues = !!this.values.inn || !!this.values.snils || !!this.values.birthCertSerial || !!this.values.birthCertNumber;

            if (isPassportValues || isFiles || otherValues) {
                this.showChangeClientModal = true;
                this.proceedToNewClient = this.proceedToChangeClient.bind(null, name);
            } else {
                this.setActiveClient(name);
            }
        } else {
            this.activeClientTab === 'over18Married' && this.clearOver18MarriedDocs();
            this.activeClientTab === 'over14' && this.clearOver14Docs();
            this.setActiveClient(name);
        }
        this.checkIfFilled();
    };

    clearOver18MarriedDocs = (): void => {
        this.values.files.notarialScan = initFormData.files.notarialScan;
        this.values.files.marriageScan = initFormData.files.marriageScan;
    };

    clearOver14Docs = (): void => {
        this.values.files.agreeParentScan = initFormData.files.agreeParentScan;
    };

    setActiveClient = (name: IAppDealClientType): void => {
        this.activeClientTab = name;
        this.resetFormErrors();
        // this.formErrors.client = false;
    };

    @action
    proceedToChangeClient = (name: IAppDealClientType) => {
        this.activeClientTab = name;
        this.showChangeClientModal = false;
        this.resetValues();
        this.resetFormErrors();
    };

    resetValues = () => {
        this.values.passport = initFormData.passport;
        this.values.files = initFormData.files;
        this.values.inn = initFormData.inn;
        this.values.snils = initFormData.snils;
        this.values.birthCertSerial = initFormData.birthCertSerial;
        this.values.birthCertNumber = initFormData.birthCertNumber;
    };

    resetPropertyPercent = () => (this.values.propertyPercent = null);

    @action
    setValues = (name: string, value: any) => {
        (this.values as any)[name] = name === 'developmentEnd' ? formatDevEnd(value) : value;
    };

    @action
    setPassportValues = (name: string, value: any) => {
        (this.values.passport as any)[name] = value;
    };

    @action
    hideChangeClientModal = () => (this.showChangeClientModal = false);

    @action
    handleChangeForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'developmentEnd') {
            (this.values as any)[event.target.name] = formatDevEnd(event.target.value);
        }

        (this.values as any)[event.target.name as keyof IAppDealClient] = event.target.value;
        this.formErrors[event.target.name as keyof IAppDealClient] = false;
        this.checkIfFilled();
    };

    @action
    handleChangeMainInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        event = inputMaskHelper(event);
        this.values.mainInfo[event.target.name as keyof IAppDealClientMainInfo] = event.target.value;
        this.formErrors[event.target.name as keyof IAppDealClientMainInfo] = false;
        this.checkIfFilled();
    };

    @action
    handleChangePassport = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.values.passport[event.target.name as keyof IAppDealClientPassport] = event.target.value;
        this.formErrors[event.target.name as keyof IAppDealClientPassport] = false;
        this.checkIfFilled();
    };

    @action
    handleChangePassportAddress = (address: string) => {
        this.values.passport.placeOfResidence = address;
        this.formErrors.placeOfResidence = false;
        this.checkIfFilled();
    };

    @action
    handleChangePropertyPercent = (event: any) => {
        if (!event.target.value.match(new RegExp(/(^[0-9][0-9]+$|^$|^|0\s$)/))) return;
        if (+event.target.value < 0) return;
        if (+event.target.value > 100) return;
        const valueChange = +event.target.value - +this.values.propertyPercent!;
        if (valueChange + stores.AppDealFormStore.maxShare > 100) return;
        stores.AppDealFormStore.addToMaxShare(valueChange);
        this.values.propertyPercent = +event.target.value;
        this.formErrors['propertyPercent'] = false;
        this.checkIfFilled();
    };

    @action
    handleDateFormChange = (date: any) => (this.values.passport.dateOfIssue = date.format('YYYY-MM-DD'));

    @action
    toggleDepositorCheckbox = (): void => {
        if (stores.AppDealFormStore.isDepositorSet && !this.values.depositor) {
            this.modalMessage = 'Только один участник может являться депонентом';
            return;
        }
        this.values.depositor = !this.values.depositor;
        stores.AppDealFormStore.setIsDepositorSet(this.values.depositor);
    };

    @action
    getAddressAutocomplete = (query: string) => {
        this.loadingAddress = true;

        API.appDeals
            .getAddress(query)
            .then(response => {
                this.addressAutocomplete = response?.data.data;
            })
            .finally(() => {
                this.loadingAddress = false;
            });
    };

    @action
    clearMessage = (): string => (this.modalMessage = '');

    @action
    setFile = (key: string, file: any) => {
        this.values.files[key as keyof IAppDealDocs].file = file;
        this.formErrors[key as keyof IAppDealDocs] = false;
    };

    @action
    setUuid = (key: string, uuid: string) => {
        const uuidKey = `${key}Uuid` as keyof IAppDealFormDataClientDocs;

        // set default file uuid
        this.uploadedFilesUuid[uuidKey] = uuid;
    };

    @action
    resetData = () => {
        this.resetFormErrors();
        this.values = initFormData;
    };

    resetFormErrors = () => (this.formErrors = {});
}
