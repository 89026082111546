import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Container, makeStyles, Typography } from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import footerBg from '../../img/footer_bg.png';
import logo from '../../img/logo.png';
import { version } from '../../../package.json';
import { transformNormalContentText } from '../../utils/transformNormalContentText';

const Footer = observer(() => {
    const classes = useStyles();
    const { SystemStore: store } = useStores();

    useEffect(() => {
        if (!store.data) {
            store.getCompanyInfo();
        }
    }, [store]);

    return (
        <Box pb={0.5} className={classes.root}>
            <Container maxWidth="xl">
                <Grid container className={classes.container}>
                    <Grid item md={12} lg={3}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={{ md: 'center', lg: 'flex-start' }}
                            height={{ xss: 'auto', lg: 'calc(100% - 20px - 16px)' }}
                            pb={{ xxs: 2, md: 4, lg: 0 }}
                        >
                            <img src={logo} alt="" />
                        </Box>
                        <Box color="#AC85E0" mb={{ xxs: 3, lg: 0 }} textAlign={{ xxs: 'center', lg: 'left' }}>
                            <Typography variant="body2">Версия&nbsp; {version}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={3} lg={3}>
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <Typography variant="h6" color="primary">
                                ЛК Риелтора
                            </Typography>
                        </Box>
                        <Box mb={{ xxs: 4, md: 0 }}>
                            <FooterMenuLink to="/">Главная</FooterMenuLink>
                            <FooterMenuLink to="/apartments">Квартиры</FooterMenuLink>
                            <FooterMenuLink to="/residential-complexes">Жилые комплексы</FooterMenuLink>
                            <FooterMenuLink to="/applications-and-deals">Заявки/Сделки</FooterMenuLink>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4} lg={3}>
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <Typography variant="h6" color="primary">
                                Полезные ссылки
                            </Typography>
                        </Box>
                        <Box mb={{ xxs: 4, md: 0 }}>
                            <FooterMenuLink
                                to={{
                                    pathname: store.links?.offer ? `${store.links.offer}` : '#',
                                    state: { fromDashboard: false },
                                }}
                                target="_blank"
                            >
                                Договор оферты
                            </FooterMenuLink>
                            <FooterMenuLink
                                to={{
                                    pathname: store.links?.loyalty ? `${store.links.loyalty}` : '#',
                                    state: { fromDashboard: false },
                                }}
                                target="_blank"
                            >
                                Правила программы
                            </FooterMenuLink>
                            <FooterMenuLink to="/feedback">Контакты</FooterMenuLink>
                            <FooterMenuLink to="/">Основной сайт</FooterMenuLink>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={5} lg={3}>
                        <Box mb={{ xxs: 2, md: 3 }}>
                            <Typography variant="h6" color="primary">
                                Контакты
                            </Typography>
                        </Box>
                        <Box mb={{ xxs: 4, md: 0 }}>
                            {store.data?.address && <FooterInfoItem label="Адрес">{store.data?.address}</FooterInfoItem>}
                            {store.data?.phone && <FooterInfoItem label="Отдел по работе с партнерами">{store.data?.phone}</FooterInfoItem>}
                            {store.data?.work_time && (
                                <FooterInfoItem
                                    label="График работы"
                                    dangerouslySetInnerHTML={{ __html: transformNormalContentText(store.data?.work_time) }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
});

const FooterMenuLink = (props: any) => {
    const classes = useStyles();

    return (
        <Box mb={{ xxs: 1, md: 2 }}>
            <Link to={props.to} className={classes.link} {...props}>
                <Typography variant="body2">{props.children}</Typography>
            </Link>
        </Box>
    );
};

const FooterInfoItem = (props: any) => {
    const classes = useStyles();

    return (
        <Box mb={2}>
            <Typography variant="body2" className={classes.label}>
                {props.label}
            </Typography>
            <Typography variant="body2" {...props}>
                {props.children}
            </Typography>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        background: `url(${footerBg}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundColor: '#F2F1F8',
    },
    container: {
        marginTop: theme.spacing(4),
    },
    link: {
        color: theme.palette.text.primary,
    },
    label: {
        color: theme.palette.text.secondary,
        marginBottom: 4,
    },
    version: {
        color: '#AC85E0',
    },
}));

export default Footer;
