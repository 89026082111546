import React, { useEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useStores } from './hooks/useStores';
import ReactNotification from 'react-notifications-component';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import 'react-notifications-component/dist/theme.css';

import HelloScreen from './screens/HelloScreen/HelloScreen';
import SignIn from './components/SignIn/SignIn';
import SignUp from './components/SignUp/SignUp';
import ResetPassword from './components/ResetPassword/ResetPassword';
import Header from './components/Header/Header';
import Loader from './components/Loader/Loader';
import Profile from './screens/Profile/Profile';
import ProfileEdit from './screens/ProfileEdit/ProfileEdit';
import ProfileChangePassword from './screens/ProfileChangePassword/ProfileChangePassword';
import Main from './screens/Main/Main';
import News from './screens/News/News';
import Complexes from './screens/Complexes/Complexes';
import Complex from './screens/Complex/Complex';
import Apartments from './screens/Apartments/Apartments';
import Apartment from './screens/Apartment/Apartment';
import MainNotAccepted from './screens/MainNotAccepted/MainNotAccepted';
import AppsDeals from './screens/AppsDeals/AppsDeals';
import Footer from './components/Footer/Footer';
import Feedback from './screens/Feedback/Feedback';
import AppDealForm from './screens/AppDealForm/AppDealForm';
import Employees from './screens/Employees/Employees';
import Stock from './screens/Stock/Stock';
import Calculator from './screens/Calculator/Calculator';
import Favorite from './screens/Favorite/Favorite';
import DocumentsScreen from './screens/Documents/DocumentsScreen';
import MyClients from './screens/MyClients/MyClientsScreen';
import LearningScreen from './screens/Learning/LearningScreen';
import AutologinScreen from 'screens/Autologin/AutologinScreen';

const App = observer(() => {
    const { AuthStore: store, ProfileStore, StockStore, SystemStore } = useStores();

    const classes = useStyles();
    const location = useLocation();

    const isAppDealsOrLearningPage = useMemo(
        () => location.pathname === '/applications-and-deals' || location.pathname === '/learning',
        [location.pathname],
    );
    const showFooter = useMemo(() => {
        switch (location.pathname) {
            case '/login':
            case '/autologin':
            case '/register':
            case '/reset-password':
            case '/apartments':
                return false;
            case '/':
                if (!store.isAuth) {
                    return false;
                }
                return true;
            default:
                return true;
        }
    }, [location.pathname, store.isAuth]);

    // Effects
    useEffect(() => {
        store.checkAuth();
    }, [store, store.isAuth]);

    useEffect(() => {
        return () => {
            ProfileStore.resetStore();
        };
    }, [ProfileStore]);

    useEffect(() => {
        if (store.isAuth) {
            ProfileStore.getProfileInfo();
            //чтобы не ждать подгрузки уведомления, если пришли с другой страницы, иначе пару секунд лоадер
            if (!StockStore.stockNotification && !StockStore.loading) {
                StockStore.getStockNotification();
            }
        }
    }, [store.isAuth, ProfileStore]);

    useEffect(() => {
        SystemStore.getDocsLinks();
    }, [SystemStore]);

    // Renders
    if (!store.completeCheckAuth) {
        return <Loader />;
    }

    const renderAuthStack = () => {
        if (!ProfileStore.userRole) {
            return <Loader />;
        }

        const confirmed = ProfileStore.userRole === 'director' || ProfileStore.userRole === 'realtor';

        return (
            <>
                <Header isAuth={true} />
                {/*после добавления зависимостей упор в контейнер*/}
                <Box className={clsx({ [classes.dealsBackground]: isAppDealsOrLearningPage })} flex="1 1 " display="flex">
                    <Switch>
                        <Route exact path="/">
                            <Box display="flex" flex={1} overflow="hidden">
                                {confirmed ? <Main /> : <MainNotAccepted />}
                            </Box>
                        </Route>
                        <Route exact path="/autologin" component={AutologinScreen} />
                        <Route exact path="/profile" component={Profile} />
                        <Route path="/profile/edit" component={ProfileEdit} />
                        <Route path="/profile/change-password" component={ProfileChangePassword} />
                        <Route exact path="/news/:newsId" component={confirmed ? News : MainNotAccepted} />
                        <Route exact path="/stock/:stockId" component={confirmed ? Stock : MainNotAccepted} />
                        <Route exact path="/residential-complexes">
                            <Box display="flex" flex={1} overflow="hidden">
                                <Complexes />
                            </Box>
                        </Route>
                        <Route exact path="/residential-complexes/:complexId">
                            <Box display="flex" flex={1} overflow="hidden">
                                <Complex />
                            </Box>
                        </Route>
                        <Route exact path="/apartments" component={Apartments} />
                        <Route path="/apartments/:apartmentId">
                            <Box display="flex" flex={1} overflow="hidden">
                                <Apartment />
                            </Box>
                        </Route>

                        <Route exact path="/applications-and-deals" component={confirmed ? AppsDeals : MainNotAccepted} />
                        <Route exact path="/learning" component={confirmed ? LearningScreen : MainNotAccepted} />
                        <Route path="/applications-and-deals/create">
                            <AppDealForm />
                        </Route>
                        <Route path="/applications-and-deals/:appDealId">
                            <AppDealForm />
                        </Route>
                        <Route path="/feedback" component={Feedback} />
                        {ProfileStore.userRole === 'director' && <Route path="/employees" component={Employees} />}
                        <Route path="/calculator" component={Calculator} />
                        <Route path="/favorite" component={Favorite} />
                        <Route exact path="/documents" component={DocumentsScreen} />

                        <Route exact path="/my-clients" component={confirmed ? MyClients : MainNotAccepted} />

                        <Route path="*">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </Box>

                <Box />

                {showFooter && <Footer />}

                <ReactNotification />
            </>
        );
    };

    return store.isAuth ? (
        renderAuthStack()
    ) : (
        <div className="auth-page">
            <Header isAuth={false} />

            <Switch>
                <Route exact path="/" component={HelloScreen} />
                <Route path="/autologin" component={AutologinScreen} />
                <Route path="/login" component={SignIn} />
                <Route path="/register" component={SignUp} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route exact path="/applications-and-deals" component={HelloScreen} />
                <Route exact path="/feedback" component={Feedback} />
                <Route path="/calculator" component={Calculator} />
                <Route exact path="/apartments" component={Apartments} />
                <Route path="/apartments/:apartmentId">
                    <Box display="flex" flex={1} overflow="hidden">
                        <Apartment />
                    </Box>
                </Route>
                <Route exact path="/residential-complexes" component={Complexes} />
                <Route exact path="/residential-complexes/:complexId">
                    <Box display="flex" flex={1} overflow="hidden">
                        <Complex />
                    </Box>
                </Route>
                <Route exact path="/my-clients" component={HelloScreen} />

                <Route path="*">
                    <Redirect to="/" />
                </Route>
            </Switch>

            {showFooter && <Footer />}

            <ReactNotification />
        </div>
    );
});

export default App;

const useStyles = makeStyles(theme => ({
    dealsBackground: {
        background: theme.palette.surface.main,
    },
}));
