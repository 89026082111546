import axios from 'axios';
import { BASE_URL } from './consts';
import { version } from '../../package.json';
import { showNotification } from '../utils/Notification';

import authAPI from './authAPI';
import profileAPI from './profileAPI';
import agencyAPI from './agencyAPI';
import complexAPI from './complexAPI';
import employeesAPI from './employeesAPI';
import newsAPI from './newsAPI';
import stockAPI from './stockAPI';
import feedbackAPI from './feedbackAPI';
import apartmentAPI from './apartmentAPI';
import filtersAPI from './filtersAPI';
import appDealsAPI from './appDealsAPI';
import systemAPI from './systemAPI';
import ratingsAPI from './ratingsAPI';
import statisticAPI from './statisticAPI';
import favoriteAPI from './favoriteAPI';

axios.interceptors.request.use(
    async config => {
        // console.log('Request', { url: config.url, ...config.headers });
        return {
            ...config,
            baseURL: BASE_URL,
            headers: {
                'Content-Type': 'application/json',
                'App-Version': version,
                'App-Platform': 'web',
                ...config.headers,
            },
        };
    },
    error => {
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    async response => {
        // console.log('Response', response);
        return response;
    },
    async error => {
        // console.log('ResponseError', error.message);
        // console.log('ResponseErrorData', error.response?.data);

        if (error.response?.data) {
            const errors = error.response.data.errors || {};

            if (Object.keys(errors).length > 0) {
                for (const key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        showNotification('danger', errors[key]);
                    }
                }
            } else {
                showNotification('danger', error.response.data.message);
            }
        }

        // let response = error.config;
        if (error.message === 'Network Error') {
            console.log('Проверьте соединение');

            return Promise.reject(error);
        }
        if (error.response?.status) {
            switch (error.response?.status) {
                case 401: {
                    console.log('Ошибка - 401');
                    if (axios.defaults.headers['Access-Token']) {
                        API.clearToken();
                        localStorage.removeItem('accessToken');
                        window.location.reload();
                    }
                    throw error;
                }
                case 425: {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    break;
                }
                case 400: {
                    console.log('Ошибка - 400');
                    throw error;
                }
                default: {
                    console.log('Ошибка на сервере');
                    throw error;
                }
            }
        }
    },
);

class APIService {
    auth = authAPI;
    profile = profileAPI;
    agency = agencyAPI;
    complex = complexAPI;
    employees = employeesAPI;
    news = newsAPI;
    stock = stockAPI;
    feedback = feedbackAPI;
    filters = filtersAPI;
    apartment = apartmentAPI;
    appDeals = appDealsAPI;
    system = systemAPI;
    ratings = ratingsAPI;
    statistic = statisticAPI;
    favorite = favoriteAPI;

    setToken = (token: string) => {
        axios.defaults.headers['Access-Token'] = token;
    };

    clearToken = () => {
        delete axios.defaults.headers['Access-Token'];
    };
}

const API = new APIService();
export default API;
