import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import NewsCarousel from '../../components/NewsCarousel/NewsCarousel';
import StockCarousel from '../../components/StockCarousel/StockCarousel';
import Statistic from '../../components/Statistic/Statistic';
import NotificationModal from '../../components/NotificationModal';

const Main = observer(() => {
    const { AuthStore, ProfileStore, NewsStore, StockStore } = useStores();
    const location = useLocation();

    useEffect(() => {
        if (AuthStore.isAuth) {
            if (!StockStore.stockNotification) {
                StockStore.getStockNotification();
            }
        }
    }, [AuthStore.isAuth]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const page = parseInt(parsed.page as string) - 1 || 0;

        NewsStore.getNewsList(page, 20);

        return () => {
            NewsStore.resetNews();
        };
    }, [location.search, NewsStore]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const page = parseInt(parsed.page as string) - 1 || 0;

        StockStore.getStocks(page, 20);

        return () => {
            StockStore.resetStocks();
        };
    }, [location.search, StockStore]);

    /*const renderRatings = () => {
        switch (ProfileStore.userRole) {
            case 'director':
                return (
                    <>
                        <Box mb={4}>
                            <Typography variant="h2">Рейтинги</Typography>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} lg={6}>
                                <Rating title="Агентства" type="agencies" />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Rating title="Менеджеры" type="deals" />
                            </Grid>
                        </Grid>
                    </>
                );
            case 'realtor':
                return (
                    <>
                        <Box mb={4}>
                            <Typography variant="h2">Рейтинг агентств</Typography>
                        </Box>

                        <Rating type="agencies" />
                    </>
                );
            default:
                return <div />;
        }
    };*/

    return (
        <Container maxWidth="xl">
            <Box mt={5} mb={16.5}>
                <Statistic type={ProfileStore.userRole} />

                {/*Скрываем рейтинг*/}
                {/*<Box mb={{ xxs: 5, sm: 10 }}>{renderRatings()}</Box>*/}

                <NewsCarousel data={NewsStore.newsList} isLoading={NewsStore.loading} />

                <StockCarousel data={StockStore.stocks} isLoading={StockStore.loading} />
            </Box>
            <NotificationModal />
        </Container>
    );
});

export default Main;
