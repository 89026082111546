import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';

import { useStores } from '../../../hooks/useStores';
import { useSetFiltersFromQueryString } from '../../../hooks/useSetFiltersFromQueryString';
import { IComplexField } from '../../../types/CommonTypes';

import ApartmentsFilterComplex from '../ApartmentsFilter/ApartmentsFilterComplex';
import ApartmentsFilterHouseNumber from '../ApartmentsFilter/ApartmentsFilterHouseNumber';
import ApartmentsFilterObjectNumber from '../ApartmentsFilter/ApartmentsFilterObjectNumber';
import ApartmentsFilterRoomsNumber from '../ApartmentsFilter/ApartmentsFilterRoomsNumber';
import ApartmentsFilterMenu from '../ApartmentsFilterMenu';

const ApartmentsFilter = observer(() => {
    const { ApartmentStore: store } = useStores();

    useSetFiltersFromQueryString();

    // Effects
    useEffect(() => {
        store.getFilters();
        store.getApartmentCount();
        store.getComplexes();
    }, [store]);

    // Handlers
    const handleComplexChange = store.withImmediatelyReset(store.handleChangeComplex);
    const handleHouseNumberChange = store.withImmediatelyReset(store.handleChangeHouseNumber);
    const handleRoomsNumberChange = store.withImmediatelyReset((e: any, value: any) =>
        store.handleAutocompleteChange('roomsNumber', value),
    );
    const handleChange = store.withImmediatelyReset(store.handleChange);
    const handleCheckBoxesChange = store.withImmediatelyReset(store.handleChangeCheckboxes);

    // Renders
    return (
        <Grid container>
            <ApartmentsFilterComplex
                multiple={true}
                value={(store.filters.complex?.length && store.filters.complex) || []}
                onChange={handleComplexChange}
                options={store.complexes}
                loading={store.filterLoading}
                getOptionLabel={(option: IComplexField) => option.name}
            />

            <ApartmentsFilterHouseNumber
                multiple={true}
                value={(store.filters.houseNumbers?.length && store.filters.houseNumbers) || []}
                onChange={handleHouseNumberChange}
            />

            <ApartmentsFilterRoomsNumber onChange={handleRoomsNumberChange} />

            <ApartmentsFilterObjectNumber onChange={handleChange} />

            <ApartmentsFilterMenu
                numberOfActiveFilters={store.numberOfActiveFilters}
                handleChange={handleChange}
                handleChangeCheckboxes={handleCheckBoxesChange}
                toggleObjectType={store.toggleObjectType}
                resetOptionalFilters={store.resetPopupFilters}
                listMenu={true}
            />
        </Grid>
    );
});

export default ApartmentsFilter;
